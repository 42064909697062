.logo-container {
  z-index: 0;
  width: 40vw;  /* Responsive width, based on viewport width */
  height: auto; /* Maintains aspect ratio */
  opacity: 1;
  position: absolute;
  // bottom: -100px;  /* Stay at the bottom */
  right: 0;  /* Stay at the right */
  margin: auto;
}

.solid-logo {
  width: 100%; /* Make the image take full width of container */
  height: auto; /* Maintains aspect ratio */
  opacity: 0; 
  z-index: 1;
}
